import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import SwiperClass from "swiper/types/swiper-class";
import { Pagination, Autoplay } from "swiper/modules";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import React from "react";

const Logo05 = () => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [autoplaying, setAutoplaying] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [page, setPage] = useState(1);

  const handleClickEvents = {
    toggleAutoplay: () => {
      if (swiper) {
        if (autoplaying) {
          swiper.autoplay.stop();
        } else {
          swiper.autoplay.start();
        }
        setAutoplaying(!autoplaying);
      }
    },
  };

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        setActiveIndex(swiper.realIndex);
      });
    }
  }, [swiper]);

  return (
    <section>
      <div className="logo__section05">
        <div className="logo__section05__content">
          <HorizontalMoveAnimation
            element="h4"
            delay={0.2}
            duration={0.3}
            direction="left"
          >
            어디셔널 로고
          </HorizontalMoveAnimation>

          <ul>
            <OpacityAnimation
              element="li"
              delay={0.2}
              duration={0.5}
              className="content"
            >
              <h4>2020년</h4>
              <ul>
                <li>
                  <img src="../imgs/png/logo-previous.png" alt="" />
                  <div className="content__infoBox">
                    <div>
                      <img src="../imgs/png/colors.png" alt="" />
                    </div>
                    <p>
                      자동차 생활의 바탕이 되어 사람들의 삶에 더 나은 행복을
                      함께 만들어 나아가겠다는 의미를 담아 자동차 모양과 상호를
                      간결하게 조합했습니다.{" "}
                    </p>
                  </div>
                </li>
                <li>
                  <img src="../imgs/png/symbol-previous.png" alt="" />
                  <div className="content__infoBox">
                    <div>
                      <img src="../imgs/png/colors.png" alt="" />
                    </div>
                    <p>
                      자동차와 쇼핑백을 조합하여 ‘자동차를 구매한다(산다) 자동차
                      쇼핑' 메시지를 직관적으로 표현했습니다.
                    </p>
                  </div>
                </li>
              </ul>
            </OpacityAnimation>
            <OpacityAnimation
              element="li"
              delay={0.4}
              duration={0.5}
              className="content"
            >
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                slidesPerGroup={1}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                loop={true}
                onRealIndexChange={(swiper: SwiperClass) =>
                  setPage(swiper.activeIndex + 1)
                }
                speed={1000}
                onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
                spaceBetween={"30px"}
              >
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_1st.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_2nd.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_3rd.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_4th.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
              </Swiper>
              <div className="pagination">
                <ul>
                  {[0, 1, 2, 3].map((index) => (
                    <li
                      key={index}
                      className={index === activeIndex ? "active" : ""}
                    >
                      <div className="progress-bar"></div>
                    </li>
                  ))}
                </ul>
                <button onClick={handleClickEvents.toggleAutoplay}>
                  {autoplaying ? (
                    <img src="../imgs/svg/stop.svg" alt="일시정지 이미지" />
                  ) : (
                    <img src="../imgs/svg/play.svg" alt="재생 이미지" />
                  )}
                </button>
              </div>
            </OpacityAnimation>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Logo05;
