import "./styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import CarsayoRouter from "./router/index";
import isMobile from "./utils/isMobile";
import { useLayoutEffect } from "react";

function App() {
  useLayoutEffect(() => {
    const currentDomain = window.location.hostname;

    if (
      isMobile() === true &&
      (currentDomain.includes("https://carsayo.net") ||
        currentDomain.includes("https://www.carsayo.net"))
    ) {
      window.location.replace("https://m.carsayo.net");
    } else if (
      isMobile() === false &&
      currentDomain.includes("m.carsayo.net")
    ) {
      window.location.replace("https://carsayo.net");
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <CarsayoRouter />
      </div>
    </BrowserRouter>
  );
}

export default App;
