import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import React from "react";

const Logo02 = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlay, setVideoPlay] = useState<boolean | null>(null);
  const handleClickEvents = {
    videoPlay: () => {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
          setVideoPlay(true);
        } else {
          videoRef.current.pause();
          setVideoPlay(false);
        }
      }
    },
  };

  return (
    <section>
      <div className="logo__section02">
        <div className="company__section__title">
          <VerticalMoveAnimation
            element="h3"
            duration={0.5}
            direction="top"
            // setAnimated={setAnimated}
          >
            BRAND FILM
            <span>브랜드 필름</span>
          </VerticalMoveAnimation>
        </div>
        <OpacityAnimation
          element="div"
          delay={0.1}
          duration={0.5}
          className="logo__section02__content"
        >
          <span>
            {isVideoPlay === true ? (
              <img src="../imgs/svg/stop.svg" alt="일시정지 이미지" />
            ) : isVideoPlay === null ? (
              <img src="../imgs/svg/stop.svg" alt="일시정지 이미지" />
            ) : (
              <img src="../imgs/svg/play.svg" alt="재생 이미지" />
            )}
          </span>

          <video
            muted
            autoPlay
            loop
            webkit-playsinline="true"
            playsInline
            preload="auto"
            ref={videoRef}
            onClick={handleClickEvents.videoPlay}
          >
            <source src="../videos/carsayo.mp4" type="video/mp4" />
          </video>
        </OpacityAnimation>
      </div>
    </section>
  );
};
export default Logo02;
