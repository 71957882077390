import { useState } from "react";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { motion } from "framer-motion";
import TypingAnimationText from "../components/TypingAnimationText";
import React from "react";

const MainSection07 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      {" "}
      <div className="wrap">
        <div className="main-section-right">
          <div className="left">
            <div>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.1, duration: 0.3 },
                }}
                onAnimationStart={() => setAnimated(false)}
                onAnimationComplete={() => setAnimated(true)}
              >
                <h3>
                  <span>내 상황에 가장 적합한</span>
                  <span>자동차 다이렉트 보험 비교</span>
                </h3>
                <span
                  className={isAnimated ? "animation" : ""}
                  // style={{ right: "35%" }}
                >
                  <UnderlineSketch fill="#FFE492" />
                </span>
              </motion.div>
            </div>
            <div>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { delay: 0.3, duration: 0.5 },
                }}
                style={{ display: "inline-block" }}
              >
                <span>
                  가장 알맞은 자동차 다이렉트 보험을 찾아 합리적인 선택할 수
                  있는 서비스를 제공합니다.
                </span>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { delay: 0.4, duration: 0.3 },
                }}
                style={{ display: "inline-block" }}
              >
                <span>
                  운전 습관과 상황에 맞춰 국내 모든 자동차 다이렉트 보험을 한
                  번에 조회 및 비교할 수 있습니다.
                </span>
              </motion.div>
            </div>
          </div>
          <div className="right">
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
            >
              <img src="../imgs/png/mockup3.png" alt="" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection07;
