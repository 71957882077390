import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import { useState } from "react";

const Logo01 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      {" "}
      <div className="logo__section01">
        <div>
          <VerticalMoveAnimation element="div" duration={0.5} direction="top">
            <h3>
              <img src="../imgs/svg/philosophy-ci.svg" alt="카사요 로고" />
            </h3>
          </VerticalMoveAnimation>
        </div>
        <HorizontalMoveAnimation
          element="div"
          duration={0.2}
          direction="left"
          setAnimated={setAnimated}
        >
          <TypingTextAnimation
            text="CI / BI"
            element="p"
            isAnimated={isAnimated}
          />
        </HorizontalMoveAnimation>
      </div>
    </section>
  );
};
export default Logo01;
