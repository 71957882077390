import { motion } from "framer-motion";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { useEffect, useState } from "react";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../utils/opacityAnimation";
import React from "react";

const ESG03 = () => {
  const [isAnimated, setAnimated] = useState(false);

  return (
    <section>
      <div className="esg__section03">
        <div className="esg__section03__title">
          <VerticalMoveAnimation
            element="div"
            duration={0.5}
            direction="top"
            setAnimated={setAnimated}
          >
            <h3>ESG가 무엇인가요?</h3>
          </VerticalMoveAnimation>
        </div>
        <p>
          <HorizontalMoveAnimation
            element="div"
            delay={0.3}
            duration={0.5}
            direction="left"
          >
            <span>
              ESG는 환경(Environmental), 사회(Social), 지배구조(Governance)의
              영문 첫 글자를 조합단 단어
            </span>
            <span>
              로 기업 경영에서 지속가능성을 달성하기 위한 3가지 핵심요소입니다.
            </span>
          </HorizontalMoveAnimation>
        </p>
        <OpacityAnimation
          className="esg__section03__content"
          element={"div"}
          delay={0.3}
          duration={0.5}
        >
          <ul>
            <li>
              <h4>
                <strong>E</strong>nvironmental
              </h4>
              <div>
                <p>기후 변화 및 탄소 배출</p>
                <p>환경 오염 및 환경규제</p>
                <p>생태계 및 생물 다양성</p>
              </div>
              <img src="../imgs/png/esg-explain01.png" alt="" />
            </li>
            <li>
              <h4>
                <strong>S</strong>ocial
              </h4>
              <div>
                <p>데이터 보호 및 프라이버시</p>
                <p>인권, 성별 평등 및 다양성</p>
                <p>지역사회 관계</p>
              </div>
              <img src="../imgs/png/esg-explain02.png" alt="" />
            </li>
            <li>
              <h4>
                <strong>G</strong>overnance
              </h4>
              <div>
                <p>이사회 및 감사위원회 구성</p>
                <p>뇌물 및 반부페</p>
                <p>기업윤리</p>
              </div>
              <img src="../imgs/png/esg-explain03.png" alt="" />
            </li>
          </ul>
        </OpacityAnimation>
      </div>
    </section>
  );
};

export default ESG03;
