import { motion } from "framer-motion";
import { useState } from "react";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import React from "react";

const CCM02 = () => {
  return (
    <section>
      <div className="cc__section02">
        <div className="cc__section02__bg"></div>
        <div className="company__section__title">
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            소비자중심경영
            <span>CCM</span>
          </VerticalMoveAnimation>
        </div>
        <div className="cc__section02__content">
          <HorizontalMoveAnimation
            element="p"
            delay={0.2}
            duration={0.5}
            direction="left"
          >
            기업이 수행하는 모든 활동을 소비자 관점에서 소비자 중심으로 구성하고
            <HorizontalMoveAnimation
              element="span"
              delay={0.4}
              duration={0.5}
              direction="left"
            >
              관련 경영 활동을 지속적으로 개선하고 있는지를 한국소비자보호원이
              평가하고 공정거래 위원회가 인증하는 제도입니다.
            </HorizontalMoveAnimation>
          </HorizontalMoveAnimation>
          <img src="../imgs/svg/CCM.svg" alt="ccm이미지" />
        </div>
      </div>
    </section>
  );
};
export default CCM02;
