import { motion } from "framer-motion";
import { useState } from "react";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import React from "react";

const Introduction02 = () => {
  return (
    <section>
      <div className="introduction__section02">
        <div className="company__section__title">
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            CEO MESSAGE
            <span>CEO 메시지</span>
          </VerticalMoveAnimation>
        </div>
        <div className="introduction__section02__content">
          <div className="box">
            <div>
              <HorizontalMoveAnimation element="span" direction="left">
                1999년부터 26년간 금융 무사고
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="span"
                delay={0.3}
                direction="left"
              >
                국산차 및 수입차 4대 브랜드 최고 세일즈 기록
              </HorizontalMoveAnimation>
            </div>
            <p>
              <span>카사요(주) 대표이사</span>
              <strong>최 용민</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Introduction02;
