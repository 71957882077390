import { motion } from "framer-motion";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { useState } from "react";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import React from "react";
import { useNavigate } from "react-router-dom";

interface MainInterface {
  currentOS: string;
}

const MainSection02 = ({ currentOS }: MainInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  const navigate = useNavigate();

  return (
    <section>
      <div className="wrap">
        <div className="main-section02">
          <div>
            <VerticalMoveAnimation
              element="div"
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
            >
              <h3>카사요는 왜 다른가요?</h3>
              <span className={isAnimated ? "animation" : ""}>
                <UnderlineSketch />
              </span>
            </VerticalMoveAnimation>
          </div>
          <div>
            <VerticalMoveAnimation element="div" delay={0.2} direction="top">
              <span>
                카 매니저님들과는 제휴가 아닌 26년 현장 경험을 바탕으로 국내
                최대 네크워크 인프라를 활용해서 안전합니다.
              </span>
            </VerticalMoveAnimation>
            <VerticalMoveAnimation element="div" delay={0.3} direction="top">
              <span>
                자동차 금융 상품을 직접 취급할 수 있어서 저렴한 견적을 제공할 수
                있습니다.
              </span>
            </VerticalMoveAnimation>
            <VerticalMoveAnimation element="div" delay={0.4} direction="top">
              <span>
                ESG 경영, 벤처기업 인증, 기업부설 연구소 설립, 특허 출원 등
                신뢰할 수 있는 기업 플랫폼입니다.
              </span>
            </VerticalMoveAnimation>
          </div>
          <CarsayoButton
            bgColor={"#4F9CF9"}
            onIcon="../imgs/svg/rightArrow.svg"
            iconSize=".875rem"
            padding="1.25rem 2.5rem"
            onClick={() => {
              currentOS === "iOS" || currentOS === "Mac"
                ? window.open(
                    `https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`
                  )
                : window.open(
                    `https://play.google.com/store/apps/details?id=com.ksapp.carsayo`
                  );
            }}
          >
            확인하기
          </CarsayoButton>
        </div>
      </div>
    </section>
  );
};

export default MainSection02;
