import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import OSCheck from "../../components/OSCheck";

const Overview02 = () => {
  const currentOS = OSCheck();
  return (
    <section>
      <div className="overview__section02">
        <div className="company__section__title">
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            INFORMATION
            <span>기업정보</span>
          </VerticalMoveAnimation>
        </div>
        <div className="overview__section02__content">
          <div className="top">
            <OpacityAnimation element="p" delay={0.1} duration={1}>
              <span>
                카사요는 자동차 관련 모든 서비스를 전문적으로 제공하는 자동차
                쇼핑 통합 플랫폼으로 참신하고 혁신적인 비즈니스 모델로 자동차
                산업에 기여하고 있습니다.
              </span>
              <span>
                정보의 비대칭을 해결하고 긍정적인 인식 변화를 이끌어 자동차
                시장을 선도할 것입니다
              </span>
            </OpacityAnimation>
            <ul>
              <li>
                <strong>카사요(주)</strong>
                <span>회사명</span>
              </li>
              <li>
                <strong>최용민</strong>
                <span>대표이사</span>
              </li>
              <li>
                <strong>2020년 8월 7일</strong>
                <span>설립일</span>
              </li>
            </ul>
          </div>
          <div className="img-box">
            <span>
              사업분야
              <img
                src="../imgs/svg/smallArrow_right_white.svg"
                alt="작은우측화살표"
              />
            </span>
            <p>
              <span>자동차 판매 및 구매,</span>
              <span>자동차 금융 및 보험, 앱 서비스 개발 외 다수</span>
            </p>
            <button
              onClick={() => {
                currentOS === "iOS" || currentOS === "Mac"
                  ? window.open(
                      `https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`
                    )
                  : window.open(
                      `https://play.google.com/store/apps/details?id=com.ksapp.carsayo`
                    );
              }}
            >
              자동차 쇼핑 통합 플랫폼
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Overview02;
