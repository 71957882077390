import { useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import TypingTextAnimation from "../../../utils/typingTextAnimation";

interface Overview01Interface {
  activeIndex: number;
  moveContent: (page: number) => void;
  handleMouseEnter: (page: number) => void;
  handleMouseLeave: () => void;
}

const Overview01 = ({
  activeIndex,
  moveContent,
  handleMouseEnter,
  handleMouseLeave,
}: Overview01Interface) => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="overview__section01">
        <div className="overview__section01__visual">
          <VerticalMoveAnimation
            element="h3"
            duration={0.3}
            delay={0}
            direction="top"
            setAnimated={setAnimated}
          >
            <img src="../imgs/svg/overview-ci.svg" alt="카사요CI" />
            <TypingTextAnimation
              text="기업 개요"
              element="span"
              isAnimated={isAnimated}
            />
          </VerticalMoveAnimation>
        </div>
        <div className="overview__section01__box">
          <ul>
            <li
              className={activeIndex === 2 ? "active" : ""}
              onClick={() => moveContent(2)}
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
            >
              <div>
                <h4>기업 정보</h4>
                <p>자동차 쇼핑 통합 플랫폼</p>
              </div>
              <button>
                <img
                  src="../imgs/svg/overview-menubtn.svg"
                  alt="기업개요 버튼"
                />
              </button>
            </li>
            <li
              className={activeIndex === 3 ? "active" : ""}
              onClick={() => moveContent(3)}
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={handleMouseLeave}
            >
              <div>
                <h4>사업 영역</h4>
                <p>자동차 시장을 선도할 하이퍼 스케일</p>
              </div>
              <button>
                <img
                  src="../imgs/svg/overview-menubtn.svg"
                  alt="기업개요 버튼"
                />
              </button>
            </li>
            <li
              className={activeIndex === 4 ? "active" : ""}
              onClick={() => moveContent(4)}
              onMouseEnter={() => handleMouseEnter(4)}
              onMouseLeave={handleMouseLeave}
            >
              <div>
                <h4>기업 연혁</h4>
                <p>지금까지 달려온, 앞으로 달려갈 길</p>
              </div>
              <button>
                <img
                  src="../imgs/svg/overview-menubtn.svg"
                  alt="기업개요 버튼"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Overview01;
