import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import CarsayoCI from "../../components/svg/CarsayoCI";
import { useRef, useState } from "react";

const Overview04Third = () => {
  // 오디오 파일
  const audioFiles = [
    "../audio/carsayoA.wav",
    "../audio/carsayoB.wav",
    "../audio/carsayoC.wav",
    "../audio/carsayoD.wav",
  ];

  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioPlay, setAudioPlay] = useState<boolean | null>(null);

  const playRandomAudio = () => {
    if (audioPlay) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setAudioPlay(null);
    } else {
      const randomIndex = Math.floor(Math.random() * audioFiles.length);
      if (audioRef.current) {
        audioRef.current.src = audioFiles[randomIndex];
        audioRef.current.play();
        setAudioPlay(true);
      }
    }
  };
  return (
    <section>
      <div className="overview__section04">
        <div className="overview__section04__content">
          <div className="overview__section04__content__wrap">
            <VerticalMoveAnimation
              element="div"
              duration={0.5}
              direction="top"
              className="title"
            >
              2022
            </VerticalMoveAnimation>
            <div className="box">
              <ul className="history">
                <HorizontalMoveAnimation
                  element="li"
                  delay={0.2}
                  direction="left"
                  className="content"
                  duration={0.5}
                >
                  <div>
                    <span>2022.07 ~</span>
                    <p>라디오 광고 (가수 배기성 나레이션 및 노래)</p>
                  </div>
                  <div
                    className="audio-box"
                    style={{ backgroundColor: "#FFD600" }}
                  >
                    <h3>
                      <CarsayoCI fill="#111" />
                    </h3>
                    <p>브랜드 오디오 클립</p>
                    <button onClick={playRandomAudio}>
                      {audioPlay ? (
                        <div>
                          <span>
                            <img
                              src="../imgs/svg/audio-stop.svg"
                              alt="정지 아이콘"
                            />
                          </span>
                          <ul
                            className={`audio-visualizer ${
                              audioPlay ? "playing" : ""
                            }`}
                          >
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                          </ul>
                        </div>
                      ) : (
                        <img
                          src="../imgs/svg/m-main-swiper-playBtn.svg"
                          alt="오디오 재생 아이콘"
                        />
                      )}

                      <audio ref={audioRef} loop />
                    </button>
                  </div>
                </HorizontalMoveAnimation>
              </ul>
            </div>
            <VerticalMoveAnimation
              element="div"
              delay={0.3}
              duration={0.5}
              direction="top"
              className="title"
              style={{ paddingTop: "3vh" }}
            >
              2021
            </VerticalMoveAnimation>
            <div className="box">
              <ul className="history">
                <HorizontalMoveAnimation
                  element="li"
                  delay={0.4}
                  direction="left"
                  className="content"
                  duration={0.5}
                >
                  <div>
                    <span>2021.11</span>
                    <p>카사요 앱 Android 버전 출시</p>
                  </div>
                  <div>
                    <img
                      src="../imgs/png/AOS_prevSimbol.png"
                      alt="구버전Android심볼"
                    />
                  </div>
                </HorizontalMoveAnimation>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Overview04Third;
