import { motion } from "framer-motion";
import { useState } from "react";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import SwiperClass from "swiper/types/swiper-class";
import Star from "../components/svg/Star";
import "swiper/swiper-bundle.css";
import React from "react";

const MainSection10 = () => {
  const [isAnimated, setAnimated] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);

  return (
    <section>
      <div className="wrap">
        <div className="main-section10">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.1, duration: 0.5 },
            }}
            onAnimationStart={() => setAnimated(false)}
            onAnimationComplete={() => setAnimated(true)}
          >
            <h3>많은 분들이 좋은 조건으로 구매했어요</h3>
            <span className={isAnimated ? "animation" : ""}>
              <UnderlineSketch fill="#FFE492" />
            </span>
          </motion.div>

          <div className="main-section10__swiper">
            <Swiper
              slidesPerView={3}
              slidesPerGroup={3}
              loop={true}
              onRealIndexChange={(swiper: SwiperClass) =>
                setPage(Math.ceil((swiper.activeIndex + 1) / 3))
              }
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              spaceBetween={"30px"}
            >
              <SwiperSlide>
                <div>
                  <img src="../imgs/png/noteBg.png" alt="" />
                  <div className="top">
                    <div>
                      <img src="../imgs/png/salesCar.png" alt="" />
                    </div>
                  </div>
                  <div className="middle">
                    <span>
                      무엇보다도 가격을 최고가로 받을 수 있는 게 좋은 앱이네요.
                      훌륭합니다.
                    </span>
                    <span>
                      모든 과정이 빠르고 간단합니다. 입찰제 중고차 판매가 마음에
                      듭니다.
                    </span>
                  </div>
                  <div className="bottom">
                    <div>
                      <p>정민서</p>
                      <p>내 차 팔기</p>
                    </div>
                    <div>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star
                          key={i}
                          fill="#FFE492"
                          width={".9375rem"}
                          height={".9375rem"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src="../imgs/png/noteBg.png" alt="" />
                  <div className="top">
                    <div>
                      <img src="../imgs/png/newCar.png" alt="" />
                    </div>
                  </div>
                  <div className="middle">
                    <span>
                      새차 구입할 때 많은 혜택도 받았어요. 추천합니다.
                    </span>
                    <span>편리하게 차를 사고 파는게 가능해서 너무 좋아요.</span>
                  </div>
                  <div className="bottom">
                    <div>
                      <p>태익김</p>
                      <p>신차 구매</p>
                    </div>
                    <div>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star
                          key={i}
                          fill="#FFE492"
                          width={".9375rem"}
                          height={".9375rem"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src="../imgs/png/noteBg.png" alt="" />
                  <div className="top">
                    <div>
                      <img src="../imgs/png/newCar.png" alt="" />
                    </div>
                  </div>
                  <div className="middle">
                    <span>앱 뛰어 납니다. 정말 좋네요.</span>
                    <span>
                      신차 구입을 했어요. 많은 혜택으로 정말 만족스러운 구매를
                      했네요.
                    </span>
                  </div>
                  <div className="bottom">
                    <div>
                      <p>람아</p>
                      <p>신차 구매</p>
                    </div>
                    <div>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star
                          key={i}
                          fill="#FFE492"
                          width={".9375rem"}
                          height={".9375rem"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src="../imgs/png/noteBg.png" alt="" />
                  <div className="top">
                    <div>
                      <img src="../imgs/png/newCar.png" alt="" />
                    </div>
                  </div>
                  <div className="middle">
                    <span>앱의 접근성 및 편리성 좋습니다.</span>
                    <span>
                      그리고 직원분들과 통화 시 매우 친절하고 신속/정확함이
                      신뢰를 줍니다.
                    </span>
                  </div>
                  <div className="bottom">
                    <div>
                      <p>김우현</p>
                      <p>신차 구매</p>
                    </div>
                    <div>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star
                          key={i}
                          fill="#FFE492"
                          width={".9375rem"}
                          height={".9375rem"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src="../imgs/png/noteBg.png" alt="" />
                  <div className="top">
                    <div>
                      <img src="../imgs/png/salesCar.png" alt="" />
                    </div>
                  </div>
                  <div className="middle">
                    <span>
                      새차를 알아보다가 추천으로 앱을 깔고 보니 내차도 팔게
                      되어있어 다 해봤습니다.
                    </span>
                    <span>
                      둘다 되니 좋습니다. 저도 지인들에게
                      추천하겠습니다.감사합니다.
                    </span>
                  </div>
                  <div className="bottom">
                    <div>
                      <p>로또</p>
                      <p>내차 팔기</p>
                    </div>
                    <div>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star
                          key={i}
                          fill="#FFE492"
                          width={".9375rem"}
                          height={".9375rem"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src="../imgs/png/noteBg.png" alt="" />
                  <div className="top">
                    <div>
                      <img src="../imgs/png/salesCar.png" alt="" />
                    </div>
                  </div>
                  <div className="middle">
                    <span>
                      이용법도 참 쉽고 간편하고 견적받으면 딜러분들이 입찰해서
                      최고가를 맞출 수 있었어요
                    </span>
                  </div>
                  <div className="bottom">
                    <div>
                      <p>머리카락카리나</p>
                      <p>내차 팔기</p>
                    </div>
                    <div>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star
                          key={i}
                          fill="#FFE492"
                          width={".9375rem"}
                          height={".9375rem"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="pagination">
              <div onClick={() => swiper?.slidePrev()}>
                <img src="../imgs/svg/Left-Arrow.svg" alt="" />
              </div>
              {/* <div>
            <span>{page}</span>/4
          </div> */}
              <div onClick={() => swiper?.slideNext()}>
                <img src="../imgs/svg/Right-Arrow.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MainSection10;
