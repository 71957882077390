import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes } from "./routes";
import { useEffect, useRef } from "react";
const CarsayoRouter: React.FC = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div ref={pageRef} className={`flex-auto w-full overflow-y-auto`}>
      <Routes location={location}>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element}>
            {route.nestedRoutes &&
              route.nestedRoutes.map((nestedRoute) => (
                <Route
                  key={`${route.path}${nestedRoute.path}`}
                  path={`${route.path}/${nestedRoute.path}`}
                  element={nestedRoute.element}
                />
              ))}
          </Route>
        ))}
      </Routes>
    </div>
  );
};
export default CarsayoRouter;
