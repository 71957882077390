import { motion } from "framer-motion";
import { useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import React from "react";

const Logo04 = () => {
  return (
    <section>
      {" "}
      <div className="logo__section04">
        <div className="company__section__title">
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            LOGOMARK
            <span>CI 소개</span>
          </VerticalMoveAnimation>
        </div>
        <div className="logo__section04__content">
          <HorizontalMoveAnimation
            element="h4"
            delay={0.2}
            duration={0.3}
            direction="left"
          >
            앱 아이콘
          </HorizontalMoveAnimation>

          <ul>
            <OpacityAnimation
              element="li"
              delay={0.2}
              duration={0.5}
              className="content"
            >
              <span>APP ICON</span>
              <div>
                <span>
                  <img src="../imgs/svg/symbol-blue.svg" alt="" />
                </span>
                <span>
                  <img src="../imgs/svg/symbol-white.svg" alt="" />
                </span>
                <span>
                  <img src="../imgs/svg/symbol-white.svg" alt="" />
                </span>
              </div>
            </OpacityAnimation>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Logo04;
