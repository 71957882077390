import { motion } from "framer-motion";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import { useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import React from "react";

const Introduction01 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="introduction__section01">
        <div>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: { delay: 0.1, duration: 0.5 },
            }}
          >
            <h3>
              <img src="../imgs/svg/introduction-logo.svg" alt="카사요 로고" />
            </h3>
          </motion.div>
        </div>
        <VerticalMoveAnimation
          element="div"
          duration={0.3}
          direction="top"
          setAnimated={setAnimated}
        >
          <TypingTextAnimation
            text="CEO소개"
            element="p"
            isAnimated={isAnimated}
          />
        </VerticalMoveAnimation>
      </div>
    </section>
  );
};
export default Introduction01;
