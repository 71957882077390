import React from "react";
import CarsayoMobileHeader from "./mobileHeader";
import CarsayoMobileFooter from "./mobileFooter";

interface CarsayoContentWrapInterface {
  children: React.ReactNode;
}

const CarsayoMobileWrap = ({ children }: CarsayoContentWrapInterface) => {
  return (
    <div className="m-main">
      <CarsayoMobileHeader />
      {children}
      <CarsayoMobileFooter />
    </div>
  );
};
export default CarsayoMobileWrap;
