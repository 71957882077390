import { useState } from "react";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { motion } from "framer-motion";
import TypingAnimationText from "../components/TypingAnimationText";
import React from "react";

const MainSection08 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="wrap">
        <div className="main-section-left">
          <div className="left">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.2, duration: 0.5 },
              }}
            >
              <img src="../imgs/png/mockup4.png" alt="" />
            </motion.div>
          </div>
          <div className="right">
            <div>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.1, duration: 0.5 },
                }}
                onAnimationStart={() => setAnimated(false)}
                onAnimationComplete={() => setAnimated(true)}
              >
                <h3>
                  <span>더 저렴하고 안전한</span>
                  <span>자동차 금융 견적 제공</span>
                </h3>
                <span
                  className={isAnimated ? "animation" : ""}
                  style={{ right: "-7%" }}
                >
                  <UnderlineSketch fill="#FFE492" />
                </span>
              </motion.div>
            </div>
            <div>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { delay: 0.3, duration: 0.3 },
                }}
                style={{ display: "inline-block" }}
              >
                <span>
                  카사요는 깨끗한 자동차 거래 시장을 만들기 위해 장기 렌트 및
                  리스 금융 견적을 직접 제안드립니다.
                </span>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { delay: 0.4, duration: 0.3 },
                }}
                style={{ display: "inline-block" }}
              >
                <span>
                  [금융상품판매 중개업자 등록증]을 보유한 플랫폼으로 더 저렴하고
                  안전한 견적을 제공이 가능합니다.
                </span>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection08;
