import { motion } from "framer-motion";
import { useState } from "react";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import React from "react";

const CCM01 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      {" "}
      <div className="cc__section01">
        <div className="cc__section01__content">
          <div className="cc__section01__content__top">
            <HorizontalMoveAnimation
              element="h4"
              delay={0.2}
              duration={0.5}
              direction="left"
              setAnimated={setAnimated}
            >
              <span>카사요는</span>
              <span>소비자 중심 경영(CCM)을 지향하여,</span>
              <span>
                고객의 필요와 요구를 최우선으로 고려하는 경영 방식을 채택하고
                있습니다
              </span>
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="p"
              delay={0.4}
              duration={0.5}
              direction="left"
            >
              <span>
                고객의 피드백을 적극적으로 수용하고 이를 바탕으로 제품과
                서비스를 개선하여
              </span>
              <span> 신뢰를 구축하는데 최선을 다하고 있습니다.</span>
            </HorizontalMoveAnimation>
          </div>
          <HorizontalMoveAnimation
            element="div"
            delay={0.4}
            duration={0.5}
            direction="right"
            className="cc__section01__content__bottom"
          >
            {/* <video
              loop
              autoPlay
              muted
              preload="auto"
              // controls
            >
              <source src="../videos/carsayo.mp4" type="video/mp4" />
            </video> */}
            <iframe
              src="https://www.youtube.com/embed/22n-JTL58HY?si=eliYX1CV1zXvsS9Z&autoplay=1&mute=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </HorizontalMoveAnimation>
        </div>
      </div>
    </section>
  );
};
export default CCM01;
