import { motion } from "framer-motion";
import { useState } from "react";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import React from "react";

const CCM03 = () => {
  return (
    <section>
      <div className="cc__section03">
        <div className="cc__section03__content">
          <HorizontalMoveAnimation
            element="p"
            delay={0.2}
            duration={0.5}
            direction="left"
          >
            카사요는
            <HorizontalMoveAnimation
              element="span"
              delay={0.4}
              duration={0.5}
              direction="left"
            >
              소비자 중심 경영(CCM)을 지향하여 고객의 피드백을 적극적으로
              수용하고 이를 바탕으로 제품과 서비스를 개선하여 신뢰를 구축하는데
              최선을 다하고 있습니다.
            </HorizontalMoveAnimation>
          </HorizontalMoveAnimation>
          <img src="../imgs/svg/ccm-business.svg" alt="ccm중정사업이미지" />
        </div>
      </div>
    </section>
  );
};
export default CCM03;
