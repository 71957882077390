import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Overview04First = () => {
  return (
    <section>
      <div className="overview__section04">
        <div
          className="overview__section04__content"
          style={{ justifyContent: "center", gap: "10vh" }}
        >
          <VerticalMoveAnimation
            element="div"
            duration={0.5}
            direction="top"
            className="title"
          >
            2024
          </VerticalMoveAnimation>
          <div className="box">
            <ul className="history">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <ul className="history__detail">
                  <li className="content">
                    <span>2024.01</span>
                    <p>
                      <span>ISO 9001(품질경영시스템)</span>
                      <span>국제 표준 인정</span>
                    </p>
                    <img
                      src="../imgs/png/certificateImg01.png"
                      alt="품질경영국제표준인정이미지"
                    />
                  </li>
                  <li className="content">
                    <span>2024.01</span>
                    <p>
                      <span>ISO 14001(환경경영시스템)</span>
                      <span>국제 표준 인정</span>
                    </p>
                    <img
                      src="../imgs/png/certificateImg02.png"
                      alt="환경경영국제표준인정이미지"
                    />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Overview04First;
