import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";

const Philosophy01 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="philosophy__section01">
        <div>
          <VerticalMoveAnimation
            element="div"
            duration={0.5}
            direction="top"
            setAnimated={setAnimated}
          >
            <h3>
              <img src="../imgs/svg/philosophy-ci.svg" alt="카사요 로고" />
            </h3>
          </VerticalMoveAnimation>
        </div>
        <VerticalMoveAnimation
          element="div"
          duration={0.1}
          direction="top"
          setAnimated={setAnimated}
        >
          <TypingTextAnimation
            text="경영이념"
            element="p"
            isAnimated={isAnimated}
          />
        </VerticalMoveAnimation>
      </div>
    </section>
  );
};
export default Philosophy01;
