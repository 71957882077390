import { motion } from "framer-motion";
import { useState } from "react";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import React from "react";

const Philosophy03 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="philosophy__section03">
        <div className="philosophy__section03__content">
          <div>
            <HorizontalMoveAnimation
              element="h4"
              delay={0.1}
              duration={0.5}
              direction="left"
            >
              CORE
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="h4"
              delay={0.3}
              duration={0.5}
              direction="left"
            >
              VALUE
            </HorizontalMoveAnimation>
          </div>
          <ul>
            <li>
              <span>1</span>
              <h5>창의적인 변화와 도전</h5>
              <p>자동차 업계의 리더이기에</p>
            </li>
            <li>
              <span>2</span>
              <h5>신뢰하는 소통과 협력</h5>
              <p>자동차 업계의 리더이기에</p>
            </li>
            <li>
              <span>3</span>
              <h5>지속적인 발전과 상생</h5>
              <p>자동차 업계의 리더이기에</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Philosophy03;
